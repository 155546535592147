import * as Sentry from "@sentry/browser";
import { parseJwt } from "./__tests__/jwt";
import { useAuthenticationStore } from "../features/authentication/authenticationStore";

type ErrorWithMessage = {
  message: string;
};

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
};

const logger = {
  user: {},
  info(...data: unknown[]) {
    console.info(...data);
  },
  error(errorOrMessage: Error | string, ...data: unknown[]) {
    const token = useAuthenticationStore.getState().getToken();
    console.error(errorOrMessage, ...data);
    const error = isErrorWithMessage(errorOrMessage)
      ? errorOrMessage
      : new Error(String(errorOrMessage));

    Sentry.captureException(error, {
      extra: {
        registrationId: parseJwt(token).registrationId,
        additionalContext: data,
      },
    });
  },
};

export default logger;
