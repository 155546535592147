import { TinRule } from "./model/TinRules";
import { IdentityConfirmationState } from "../features/personal-info/tins/tinsStore";

export const SERVER_API_URL = String(import.meta.env.VITE_BASE_URL);
export const AUTH_HEADER = "oa-token";
export const TIMEOUT = 60 * 1000;
export const UNAUTHORIZED = 401;
export const SERVICE_UNAVAILABLE = 503;
export const CONFIRMATION_PAGE_INDEX_WITHOUT_PAYMENT = 5;
export const CONFIRMATION_PAGE_INDEX_WITH_PAYMENT = 6;
export const DEFAULT_COUNTRY_CODES = "DEFAULT_COUNTRY_CODES";
export const DIGITS_AND_OR_LATIN_LETTERS_REGEX = /^[a-zA-Z0-9]+$/;
export const IS_PROD = import.meta.env.VITE_ENVIRONMENT_NAME === "prod";
export const IS_LOCAL = import.meta.env.VITE_ENVIRONMENT_NAME === "LOCAL";
export const DEFAULT_MINIMUM_PHONE_NUMBER_LENGTH = 7;
export const DEFAULT_MAXIMUM_PHONE_NUMBER_LENGTH = 15;
export const DEFAULT_ADDRESS_LINE_PATTERN = RegExp(
  /^[\p{Lower}\p{Upper}\s\-,./'’\d]*$/,
  "u"
);
export const DEFAULT_NAMES_PATTERN = RegExp(
  /^[\p{Lower}\p{Upper}\s\-'ʻ`‘\d]*$/,
  "u"
);
const CEUTA_AND_MELILLA_POSTAL_CODE_REGEX =
  /^(5100[1-5]|5107[0-1]|51080|5200[1-6]|5207[0-1]|52080)$/;

const SPANISH_FOREIGNER_ID_REGEX = "^[a-zA-Z]\\d{7}[a-zA-Z]$";
const SPANISH_TAX_ID_REGEX = "^\\d{8}[a-zA-Z]$";
const SPANISH_TIN_PATTERN = new RegExp(
  `${SPANISH_FOREIGNER_ID_REGEX}|${SPANISH_TAX_ID_REGEX}`
);

const defaultFieldLength = {
  address: 60,
  city: 60,
  firstName: 20,
  lastName: 30,
  middleInitial: 20,
  mothersMaidenName: 30,
  placeOfBirth: 30,
  postalCode: 4,
  province: 30,
  registrationNumber: 15,
  spouseFirstName: 30,
  spouseMiddleName: 20,
  spouseLastName: 20,
};

interface ValidationRulesInterface {
  [country: string]: {
    cityLength: number;
    addressLength: number;
    residentTins?: TinRule[];
    [key: string]:
      | null
      | string
      | number
      | boolean
      | object
      | TinRule[]
      | undefined;
  };
}

interface PhoneNumberRules {
  [countryCode: string]: {
    phoneNumberMinLength: number;
    phoneNumberMaxLength: number;
  };
}

export const VALIDATION_RULES: ValidationRulesInterface = {
  // Armenia
  "hy-AM": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: defaultFieldLength.postalCode,
    postalCodeMask: false,
    postalCodePattern: /^\d{4}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: 40,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpPickUpSection: false,
    showLatinInfo: true,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showHmpPriceContent: true,
    showOrderHandlerInformation: true,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: true,
        minLength: 4,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
      },
    ],
  },

  // Austria
  "de-AT": {
    addressLength: 40,
    addressPattern: DEFAULT_ADDRESS_LINE_PATTERN,
    adultAge: 18,
    cityLength: 30,
    firstNameLength: defaultFieldLength.firstName,
    footerLogos: [
      {
        src: "logos/Visa.png",
        alt: "Maestro logo",
      },
      {
        src: "logos/Mastercard.png",
        alt: "Mastercard logo",
      },
      {
        src: "logos/PayPal.png",
        alt: "PayPal logo",
      },
      {
        src: "logos/sofort.png",
        alt: "Sofort logo",
      },
      {
        src: "logos/dsa.png",
        alt: "Visa logo",
      },
    ],
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 4,
    postalCodeMask: "####",
    postalCodePattern: /^[0-9]{4}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showGsgBenefits: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    showSocialMediaStatement: true,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    showPaymentDisclaimer: true,
    supportedCountryCodes: ["at"],
    defaultCountryCode: "+43|Austria",
  },

  // Azerbaijan
  "az-AZ": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 6,
    postalCodeMask: false,
    postalCodePattern: /^[Aa]{1}[Zz]{1}[0-9]{4}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpPickUpSection: false,
    showLatinInfo: true,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showHmpPriceContent: true,
    showOrderHandlerInformation: true,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: false,
        minLength: 0,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
      },
    ],
  },

  // Belarus
  "ru-BY": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: true,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: true,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 6,
    postalCodeMask: false,
    postalCodePattern: /^\d{6}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: 60,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: false,
    showResidentRadioBox: true,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: true,
        minLength: 4,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: true,
        attachments: [
          { required: true, key: "file-resident-0", index: 0 },
          { required: true, key: "file-resident-1", index: 1 },
          { required: true, key: "file-resident-2", index: 2 },
        ],
      },
    ],
    nonResidentTins: [
      {
        key: "passport",
        required: true,
        minLength: 4,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: true,
        attachments: [
          { required: true, key: "file-non-resident-0", index: 0 },
          { required: false, key: "file-non-resident-1", index: 1 },
        ],
      },
      {
        key: "second section",
        required: true,
        minLength: 4,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: true,
        additional: true,
        attachments: [
          { required: true, key: "file-non-resident-0", index: 2 },
          { required: false, key: "file-non-resident-1", index: 3 },
        ],
      },
    ],
  },

  // Belgium NL
  "nl-BE": {
    addressLength: 40,
    addressPattern:
      /^(?=.*[a-zA-ZÁáÀàÂâÇçÉéÈèÊêİiÎîÔôŒœÙù])(?=.*\d)[a-zA-ZÁáÀàÂâÇçÉéÈèÊêİiÎîÔôŒœÙù\d\s]+$/,
    adultAge: 18,
    cityLength: 30,
    defaultCountryCode: "+32|Belgium",
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    middleInitial: false,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 4,
    postalCodeMask: "####",
    postalCodePattern: /^\d{4}$/,
    postalCodeRequired: true,
    province: false,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPaymentAcknowledgment: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    showSocialMediaStatement: true,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "tinType",
        required: false,
        minLength: 10,
        maxLength: 12,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        documentTypes: [
          {
            key: "BEVT",
            pattern: /^BE(?!0123456789|1234567890)[0-1]\d{9}$/g,
            defaultValue: "BE",
          },
          {
            key: "BENO",
            pattern: /^[0-1](?!123456789|234567890)\d{9}$/g,
          },
        ],
      },
    ],
  },

  // Belgium FR
  "fr-BE": {
    addressLength: 40,
    addressPattern:
      /^(?=.*[A-Za-zÁÀàÂâÇçÉéÈèÊêİiÎîÔôŒœÙù])(?=.*\d)[A-Za-zÁÀàÂâÇçÉéÈèÊêİiÎîÔôŒœÙù\d\s]+$/,
    adultAge: 18,
    cityLength: 30,
    defaultCountryCode: "+32|Belgium",
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    middleInitial: false,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 4,
    postalCodeMask: "####",
    postalCodePattern: /^\d{4}$/,
    postalCodeRequired: true,
    province: false,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPaymentAcknowledgment: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    showSocialMediaStatement: true,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "tinType",
        required: false,
        minLength: 10,
        maxLength: 14,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        documentTypes: [
          {
            key: "BEVT",
            pattern: /^BE(?!0123456789|1234567890)[0-1]\d{9}$/g,
            defaultValue: "BE",
          },
          {
            key: "BENO",
            pattern: /^[0-1](?!123456789|234567890)\d{9}$/g,
          },
        ],
      },
    ],
  },

  // Botswana
  "en-BW": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: defaultFieldLength.postalCode,
    postalCodeMask: false,
    postalCodePattern: /^[a-zA-Z0-9]{4}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpPickUpSection: true,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: false,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: false,
        minLength: 0,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        attachments: [{ required: true, key: "file-resident-0", index: 0 }],
      },
    ],
  },

  // Bulgaria
  "bg-BG": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: 30,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: true,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: defaultFieldLength.postalCode,
    postalCodeMask: false,
    postalCodePattern: /^\d{4}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDeliveryDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: true,
        minLength: 10,
        maxLength: 10,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
      },
    ],
  },

  // Czech Republic
  "cs-CZ": {
    addressLength: 40,
    adultAge: 18,
    cityLength: 30,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 6,
    postalCodeMask: "### ##",
    postalCodePattern: /^[0-9]{3}[" "][0-9]{2}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    showPaymentDisclaimer: true,
    supportedCountryCodes: ["cz"],
    defaultCountryCode: "+420|The Czech Republic",
    residentTins: [
      {
        key: "Rodne Cislo",
        required: true,
        minLength: 9,
        maxLength: 10,
        pattern: /[0-9]{6}\/[0-9]{3,4}$/,
        showTinDates: false,
      },
    ],
    nonResidentTins: [],
    tinMask: "######/####",
  },

  // Cyprus
  "el-CY": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: defaultFieldLength.postalCode,
    postalCodeMask: false,
    postalCodePattern: /^\d{4}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: true,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: ["el"],
    defaultCountryCode: "+357|Cyprus",
    residentTins: [
      {
        key: "ssn",
        required: false,
        minLength: 0,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
      },
    ],
  },

  // Estonia
  "et-EE": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 5,
    postalCodeMask: false,
    postalCodePattern: /^\d{5}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: true,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: true,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: true,
        minLength: 4,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
      },
    ],
  },

  // Germany
  "de-DE": {
    addressLength: 40,
    addressPattern: DEFAULT_ADDRESS_LINE_PATTERN,
    adultAge: 18,
    cityLength: 30,
    firstNameLength: defaultFieldLength.firstName,
    footerLogos: [
      {
        src: "logos/Visa.png",
        alt: "Maestro logo",
      },
      {
        src: "logos/Mastercard.png",
        alt: "Mastercard logo",
      },
      {
        src: "logos/PayPal.png",
        alt: "PayPal logo",
      },
      {
        src: "logos/sofort.png",
        alt: "Sofort logo",
      },
      {
        src: "logos/dsa.png",
        alt: "Visa logo",
      },
    ],
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 5,
    postalCodeMask: "#####",
    postalCodePattern: /^[0-9]{5}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showGsgBenefits: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    showSocialMediaStatement: true,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    showPaymentDisclaimer: true,
    supportedCountryCodes: ["de"],
    defaultCountryCode: "+49|Germany",
  },

  // Georgia
  "ka-GE": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: defaultFieldLength.postalCode,
    postalCodeMask: false,
    postalCodePattern: /^\d{4}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: true,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: false,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: true,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: true,
        minLength: 4,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
      },
    ],
  },

  // Hungary
  "hu-HU": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: true,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: true,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: true,
    placeOfBirth: true,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: true,
    postalCodeLength: defaultFieldLength.postalCode,
    postalCodeMask: false,
    postalCodePattern: /^\d{4}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssnOrPasswordOrDriving",
        required: true,
        minLength: 8,
        maxLength: 9,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        documentTypes: [
          { key: "ID_CARD", pattern: /^\d{6}[a-zA-Z]{2}$/g },
          { key: "DRIVING_LICENSE", pattern: /^[a-zA-Z]{2}\d{6}$/g },
          { key: "PASSPORT", pattern: /^[a-zA-Z]{2}\d{7}$/g },
        ],
      },
    ],
  },

  // Kyrgystan
  "ru-KG": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: true,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 6,
    postalCodeMask: false,
    postalCodePattern: /^\d{6}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: false,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: true,
        minLength: 14,
        maxLength: 14,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        attachments: [{ required: true, key: "file-resident-0", index: 0 }],
      },
    ],
  },

  // Latvia
  "lv-LV": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 6,
    postalCodeMask: false,
    postalCodePattern: /^[Ll]{1}[Vv]{1}[0-9]{4}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: true,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: true,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: true,
        minLength: 4,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
      },
    ],
  },

  // Lebanon
  "en-LB": {
    addressLength: 50,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 10,
    postalCodeMask: false,
    postalCodePattern: /^[a-zA-Z0-9]{10}$/,
    postalCodeRequired: false,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: false,
    showPdfContainer: false,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: true,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: true,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: true,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: true,
        minLength: 12,
        maxLength: 12,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        attachments: [{ required: true, key: "file-resident-0", index: 0 }],
      },
    ],
  },

  // Lesotho
  "en-LS": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: defaultFieldLength.postalCode,
    postalCodeMask: false,
    postalCodePattern: /^[a-zA-Z0-9]{4}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpPickUpSection: true,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: false,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: false,
        minLength: 0,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        attachments: [{ required: true, key: "file-resident-0", index: 0 }],
      },
    ],
  },

  // Lithuania
  "lt-LT": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 7,
    postalCodeMask: false,
    postalCodePattern: /^[Ll]{1}[Tt]{1}[0-9]{5}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: true,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showPdfContainer: false,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: true,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: true,
        minLength: 4,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
      },
    ],
  },

  // Malta
  "en-MT": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 7,
    postalCodeMask: false,
    postalCodePattern: /^[a-zA-Z]{2,3}[0-9]{4}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
  },

  // Namibia
  "en-NA": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: defaultFieldLength.postalCode,
    postalCodeMask: false,
    postalCodePattern: /^[a-zA-Z0-9]{4}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: false,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: false,
        minLength: 0,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        attachments: [{ required: true, key: "file-resident-0", index: 0 }],
      },
    ],
  },

  // Poland
  "pl-PL": {
    addressLength: 40,
    adultAge: 18,
    cityLength: 30,
    firstNameLength: defaultFieldLength.firstName,
    footerLogos: [
      {
        src: "logos/dsa.png",
        alt: "dsa logo",
      },
      {
        src: "logos/pssb.png",
        alt: "pssb logo",
        urlLink: "https://pssb.pl",
      },
      {
        src: "logos/wfdsa.png",
        alt: "wfdsa logo",
      },
    ],
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 6,
    postalCodeMask: "##-###",
    postalCodePattern: /^[0-9]{2}-[0-9]{3}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPaymentAcknowledgment: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: ["pl"],
    defaultCountryCode: "+48|Poland",
  },

  // Slovakia
  "sk-SK": {
    addressLength: 40,
    adultAge: 18,
    cityLength: 30,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: true,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 6,
    postalCodeMask: "### ##",
    postalCodePattern: /^[0-9]{3}[" "][0-9]{2}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showPaymentDisclaimer: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: ["sk"],
    defaultCountryCode: "+421|Slovakia",
    residentTins: [
      {
        key: "Rodne Cislo",
        required: true,
        minLength: 9,
        maxLength: 10,
        pattern: /^[0-9]{9,10}$/,
        showTinDates: false,
      },
    ],
    nonResidentTins: [],
  },

  // Spain
  "es-ES": {
    addressLength: 50,
    adultAge: 18,
    cityLength: 40,
    firstNameLength: defaultFieldLength.firstName,
    footerLogos: [
      {
        src: "logos/Visa.png",
        alt: "Maestro logo",
      },
      {
        src: "logos/Mastercard.png",
        alt: "Mastercard logo",
      },
      {
        src: "logos/PayPal.png",
        alt: "PayPal logo",
      },
      {
        src: "logos/sofort.png",
        alt: "Sofort logo",
      },
      {
        src: "logos/dsa.png",
        alt: "Visa logo",
      },
    ],
    genderFieldRequired: true,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 5,
    postalCodeMask: "#####",
    postalCodePattern: /^[0-9]{5}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showGsgBenefits: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    showSocialMediaStatement: true,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    showPaymentDisclaimer: true,
    supportedCountryCodes: ["es"],
    defaultCountryCode: "+34|Spain",
    residentTins: [
      {
        key: "ssn",
        required: true,
        visibleOn: [
          "$identityConfirmationState",
          (identityConfirmationState: IdentityConfirmationState) =>
            identityConfirmationState !== IdentityConfirmationState.FAILED,
        ],
        minLength: 0,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: SPANISH_TIN_PATTERN,
        showTinDates: false,
        attachments: [
          {
            visibleOn: [
              "postalCode",
              (postalCode: string) =>
                CEUTA_AND_MELILLA_POSTAL_CODE_REGEX.test(postalCode),
            ],
            required: true,
            key: "file-resident-0",
            index: 0,
          },
          {
            visibleOn: [
              "$identityConfirmationState",
              (identityConfirmationState: IdentityConfirmationState) =>
                identityConfirmationState === IdentityConfirmationState.FAILED,
            ],
            required: true,
            key: "file-resident-1",
            index: 1,
          },
        ],
      },
    ],
  },

  // Swaziland
  "en-SZ": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: defaultFieldLength.postalCode,
    postalCodeMask: false,
    postalCodePattern: /^[a-zA-Z0-9]{4}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: false,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: false,
        minLength: 0,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        attachments: [{ required: true, key: "file-resident-0", index: 0 }],
      },
    ],
  },

  // Switzerland german
  "de-CH": {
    addressLength: 40,
    addressPattern: DEFAULT_ADDRESS_LINE_PATTERN,
    adultAge: 18,
    cityLength: 30,
    firstNameLength: defaultFieldLength.firstName,
    footerLogos: [
      {
        src: "logos/Visa.png",
        alt: "Visa logo",
      },
      {
        src: "logos/Mastercard.png",
        alt: "Mastercard logo",
      },
    ],
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 4,
    postalCodeMask: "####",
    postalCodePattern: /^[0-9]{4}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showGsgBenefits: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    showSocialMediaStatement: true,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    showPaymentDisclaimer: true,
    supportedCountryCodes: ["ch"],
    defaultCountryCode: "+41|Switzerland",
  },

  // Switzerland french
  "fr-CH": {
    addressLength: 40,
    addressPattern: DEFAULT_ADDRESS_LINE_PATTERN,
    adultAge: 18,
    cityLength: 30,
    firstNameLength: defaultFieldLength.firstName,
    footerLogos: [
      {
        src: "logos/Visa.png",
        alt: "Visa logo",
      },
      {
        src: "logos/Mastercard.png",
        alt: "Mastercard logo",
      },
    ],
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 4,
    postalCodeMask: "####",
    postalCodePattern: /^[0-9]{4}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showGsgBenefits: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    showSocialMediaStatement: true,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    showPaymentDisclaimer: true,
    supportedCountryCodes: ["ch"],
    defaultCountryCode: "+41|Switzerland",
  },

  // Switzerland italian
  "it-CH": {
    addressLength: 40,
    addressPattern: DEFAULT_ADDRESS_LINE_PATTERN,
    adultAge: 18,
    cityLength: 30,
    firstNameLength: defaultFieldLength.firstName,
    footerLogos: [
      {
        src: "logos/Visa.png",
        alt: "Visa logo",
      },
      {
        src: "logos/Mastercard.png",
        alt: "Mastercard logo",
      },
    ],
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 4,
    postalCodeMask: "####",
    postalCodePattern: /^[0-9]{4}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showGsgBenefits: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    showSocialMediaStatement: true,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    showPaymentDisclaimer: true,
    supportedCountryCodes: ["ch"],
    defaultCountryCode: "+41|Switzerland",
  },

  // UK
  "en-GB": {
    addressLength: 40,
    address2Length: 40,
    adultAge: 18,
    cityLength: 30,
    firstNameLength: defaultFieldLength.firstName,
    footerLogos: [
      {
        src: "logos/Maestro.png",
        alt: "Maestro logo",
      },
      {
        src: "logos/Mastercard.png",
        alt: "Mastercard logo",
      },
      {
        src: "logos/PayPal.png",
        alt: "PayPal logo",
      },
      {
        src: "logos/Visa.png",
        alt: "Visa logo",
      },
    ],
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: false,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 7,
    postalCodeMask: false,
    postalCodePattern: /^[a-zA-Z]{1,2}[0-9R][0-9a-zA-Z]?[0-9][a-zA-Z]{2}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultFieldLength.province,
    showAddress2: true,
    showDateOfBirthDisclaimer: true,
    showDocumentationBox: false,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLegalStatementOnAgreementPage: true,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showMobilePhoneDisclaimer: true,
    showOrderHandlerInformation: false,
    showPaymentAcknowledgment: true,
    showPaymentDisclaimer: true,
    showPaymentMethods: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    showSocialMediaStatement: true,
    showTinInputForNonResidents: false,
    showGsgBenefits: true,
    showHmpBenefits: true,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    supportedCountryCodes: ["gb"],
    defaultCountryCode: "+44|United Kingdom",
  },

  // Uzbekistan
  "ru-UZ": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 6,
    postalCodeMask: false,
    postalCodePattern: /^[0-9]{6}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: false,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: true,
        minLength: 7,
        maxLength: 13,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        attachments: [{ required: true, key: "file-resident-0", index: 0 }],
      },
    ],
  },
  "uz-UZ": {
    addressLength: defaultFieldLength.address,
    adultAge: 18,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 6,
    postalCodeMask: false,
    postalCodePattern: /^[0-9]{6}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: false,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: true,
        minLength: 7,
        maxLength: 13,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        attachments: [{ required: true, key: "file-resident-0", index: 0 }],
      },
    ],
  },

  // Zambia
  "en-ZM": {
    addressLength: defaultFieldLength.address,
    adultAge: 21,
    cityLength: defaultFieldLength.city,
    firstNameLength: defaultFieldLength.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultFieldLength.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultFieldLength.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultFieldLength.mothersMaidenName,
    mothersMaidenNameRequired: false,
    placeOfBirth: false,
    placeOfBirthLength: defaultFieldLength.placeOfBirth,
    placeOfBirthRequired: false,
    postalCodeLength: 5,
    postalCodeMask: false,
    postalCodePattern: /^[a-zA-Z0-9]{5}$/,
    postalCodeRequired: true,
    province: true,
    provinceLength: defaultFieldLength.province,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: false,
    showLinkToOrderConfirmation: true,
    showPdfContainer: false,
    showResidentRadioBox: false,
    showTinInputForNonResidents: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultFieldLength.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultFieldLength.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultFieldLength.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    residentTins: [
      {
        key: "ssn",
        required: false,
        minLength: 0,
        maxLength: defaultFieldLength.registrationNumber,
        pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
        showTinDates: false,
        attachments: [{ required: true, key: "file-resident-0", index: 0 }],
      },
    ],
  },
};

export const MOBILE_RULES: PhoneNumberRules = {
  // Austria
  "43": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 11,
  },
  // Belgium
  "32": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 10,
  },
  // Czech Republic
  "420": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 9,
  },
  // Cyprus
  "357": {
    phoneNumberMinLength: 8,
    phoneNumberMaxLength: 8,
  },
  // Germany
  "49": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 13,
  },
  // Poland
  "48": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 9,
  },
  // Slovakia
  "421": {
    phoneNumberMinLength: 9,
    phoneNumberMaxLength: 9,
  },
  // Switzerland
  "41": {
    phoneNumberMinLength: 8,
    phoneNumberMaxLength: 11,
  },
  // UK
  "44": {
    phoneNumberMinLength: 10,
    phoneNumberMaxLength: 10,
  },
};
