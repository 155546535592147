import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import * as Sentry from "@sentry/browser";
import { parseJwt } from "../../utils/__tests__/jwt";
import { useAuthenticationStore } from "../../features/authentication/authenticationStore";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const token = useAuthenticationStore((state) => state.token);

  useEffect(() => {
    const pageNotFoundError = new Error(
      `Page not found: 404 error at ${location.pathname}`
    );
    pageNotFoundError.name = "Page not found";
    Sentry.captureException(pageNotFoundError, {
      extra: { registrationId: parseJwt(token).registrationId },
    });
  }, [location]);

  return (
    <div className={styles.notFoundPage}>
      <section
        className={styles.notFoundContainer}
        data-testid="not-found-container"
      >
        <span>4</span>
        <span>
          <span className={styles.transformedDigit}>0</span>
        </span>
        <span>4</span>
        <h3>{t("PAGE_NOT_FOUND")}</h3>
      </section>
      <Link to="/register">{t("GO_HOME")} </Link>
    </div>
  );
};

export default NotFoundPage;
