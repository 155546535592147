import { useScript } from "../../utils/useScript";

export const areCookiesEnabled = () =>
  import.meta.env.VITE_COOKIES_ENABLED === "true";

export const useCookieScript = () =>
  areCookiesEnabled() &&
  useScript(
    "https://assets.adobedtm.com/78ef23cd3941/9347026afa2d/launch-1901af4e408c.min.js"
  );
